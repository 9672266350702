"use client";
import ingredients from "@/data/ingredients.json";
import { desactivateBorderTouch, getIsTouchDevice } from "@/functions/device";
import FilterGroupType from "@/model/filter-group";
import { allDarkColors, allLightColors } from "@/providers/theme/theme-data";
import { useThemeArea } from "@/providers/theme/theme-provider";
import { useWebDataContext } from "@/providers/web-data/web-data-provider";
import { useWebUiContext } from "@/providers/webui/web-ui-provider";
import { MenuWithCategoriesIdType } from "@lifizy/domain-next";
import { Button } from "@lifizy/ui";
import { useLayoutEffect, useState } from "react";
import { TbFilterX } from "react-icons/tb";
import createAnimation from "../animation/gsap";
import Cart from "./cart/cart";
import { FlatCategory } from "./category/flat/flat-category";
import SliderCategory from "./category/slider/slider-category";
import Draggable from "./draggable";
import ItemDialog from "./item/item-dialog";
import "./menu.css";
import FilterDialog from "./search/filter-dialog";
export default function MenuStandard({ menu }: { menu: MenuWithCategoriesIdType }): JSX.Element {
  const { selectedItemId } = useWebUiContext();
  //const { webAdminForm } = useEditUiContext();
  const [filterGroups, setFilterGroups] = useState<FilterGroupType[]>(ingredients);
  const [filters, setFilters] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const themeArea = useThemeArea("web");
  const {
    oData: { items, categories },
    getItemChoiceGroup,
  } = useWebDataContext();

  useLayoutEffect(() => {
    createAnimation();
    if (getIsTouchDevice()) desactivateBorderTouch();
    console.log(menu);
  }, []);

  if (!themeArea) return <div>No theme defined</div>;
  const hasMulticolor = themeArea.theme.menuOptions.includes("multicolor");
  const hasSlider = themeArea.theme.menuOptions.includes("slider");
  const hasDensity = themeArea.theme.menuOptions.includes("density");
  const hasFull = themeArea.theme.menuOptions.includes("full");
  //const hasDelarte = themeArea.theme.menuOptions.includes("delarte");
  const dark = themeArea.theme.modes.includes("dark");
  const colors = dark ? allDarkColors : allLightColors;
  const textColor = !dark && hasMulticolor ? "text-black" : "text-white";

  function onFilter(id: string | null, elementLabel: string | null): void {
    if (id !== null) {
      const filterGroup = filterGroups.find((i) => i.id === id);
      if (!filterGroup) return;
      const element = filterGroup.elements.find((e) => e.label === elementLabel);
      if (!element) return;
    }
    if (elementLabel)
      if (filters.includes(elementLabel)) setFilters(filters.filter((f) => f !== elementLabel));
      else setFilters([...filters, elementLabel]);

    const newFilterGroups = filterGroups.map((g) => {
      if (g.id !== id) return g;
      return {
        ...g,
        elements: g.elements.map((e) => {
          if (e.label !== elementLabel) return e;
          return {
            ...e,
            value: !e.value,
          };
        }),
      };
    });
    setFilterGroups(newFilterGroups);
  }
  function displayItemDialog() {
    if (!selectedItemId) return;
    const item = items.find((i) => i.id === selectedItemId);
    if (!item) return;
    // const category = categories.find((c) => c.itemIds.includes(item.id!));
    // if (!category) return;
    //if (webAdminForm === "menu") return <ItemEditDialog item={item} steps={editStep} />;
    const itemChoiceGroup = getItemChoiceGroup(item.id);
    if (!itemChoiceGroup) return <div>Error : no item choice group</div>;
    return <ItemDialog item={item} itemChoiceGroup={itemChoiceGroup} />;
  }
  return (
    <div className="max-w-[1900px]">
      <div className="flex mx-auto">
        <div className={`grow @container ${textColor} bg-background`}>
          {/* <Button
            variant="secondary"
            className="mt-10"
            onClick={() => setItemChoiceGroup(menu.groupId)}>
            INIT
          </Button> */}
          {(filters.length !== 0 || search !== "") && (
            <Draggable>
              <div id="draggable" className="pt-2 pl-2 flex flex-row gap-2 overflow-auto">
                {search !== "" && (
                  <Button variant="outline">
                    {search} <TbFilterX className="pl-1 w-5 h-5" onClick={() => setSearch("")} />
                  </Button>
                )}
                {filters.map((filter) => (
                  <Button key="filter" variant="outline" onClick={() => onFilter(null, filter)}>
                    {filter} <TbFilterX className="pl-1  w-5 h-5" />
                  </Button>
                ))}
              </div>
            </Draggable>
          )}
          {/* {!hasSlider && (
            <Draggable>
              <div
                id="draggable"
                className="py-2 pl-2 flex flex-row gap-2 overflow-auto bg-background">
                {menuData.categories.map((c) => (
                  <Link href={`menu#${c.code}`} key={c.code}>
                    <Button variant="outline">{c.label}</Button>
                  </Link>
                ))}
              </div>
            </Draggable>
          )} */}
          {hasSlider ? (
            <div
              className={`pt-8 ${hasMulticolor ? "bg-background text-text" : "bg-gradient-to-br from-gradient-from to-gradient-to"}`}>
              {categories.map((c, idx) => (
                <div
                  key={c.id}
                  className={`${hasMulticolor ? (colors.length > idx ? colors[idx] : colors[0]) : ""}`}>
                  <SliderCategory
                    category={c}
                    hasDensity={hasDensity}
                    filters={filters}
                    search={search}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="">
              {categories.map((c) => (
                <FlatCategory
                  key={c.id}
                  category={c}
                  hasMulticolor={hasMulticolor}
                  hasDensity={hasDensity}
                  colors={colors}
                  textColor={textColor}
                  search={search}
                  filters={filters}
                  hasFull={hasFull}
                />
              ))}
            </div>
          )}
        </div>
        <FilterDialog
          onFilter={onFilter}
          filterGroups={filterGroups}
          search={search}
          setSearch={(search: string) => setSearch(search)}
          clear={() => {
            setSearch("");
            setFilters([]);
          }}
        />
        {displayItemDialog()}
        <Cart />
      </div>
    </div>
  );
}
