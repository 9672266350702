"use client";
import FilterGroupType from "@/model/filter-group";
import { AccordionContent, AccordionItem, AccordionTrigger, Toggle } from "@lifizy/ui";
import { FaCheck } from "react-icons/fa6";
interface FilterGroupProps {
  onFilter: (id: string, element: string | null) => void;
  filterGroup: FilterGroupType;
}
export default function FilterGroup({ onFilter, filterGroup }: FilterGroupProps): JSX.Element {
  function elements() {
    return filterGroup.elements.map((e, idx) => (
      <Toggle
        variant="outline"
        className="m-1 hover:bg-transparent"
        key={idx}
        pressed={e.value}
        onPressedChange={() => {
          onFilter(filterGroup.id, e.label);
        }}>
        <FaCheck className={`mr-2 ${e.value ? "" : "hidden"}`} />
        {e.label}
      </Toggle>
    ));
  }
  return (
    <AccordionItem value={filterGroup.id}>
      <AccordionTrigger>{filterGroup.label}</AccordionTrigger>
      <AccordionContent>{elements()}</AccordionContent>
    </AccordionItem>
  );
}
