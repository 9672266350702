import {
  useOrderManagerContext,
  useOrderManagerDispatch,
} from "@/providers/order/order-manager-provider";
import { Button } from "@lifizy/ui";
import { useState } from "react";
import { IoCartOutline } from "react-icons/io5";
import { MdClear } from "react-icons/md";
import { formatPrice } from "../../../functions/dataFormat";
export default function Cart() {
  const { order } = useOrderManagerContext();
  const orderMngAction = useOrderManagerDispatch();
  const [displayItems, setDisplayItems] = useState<boolean>(false);
  function onClear() {
    orderMngAction({ type: "clear" });
  }
  function onRemove(orderLineId: string) {
    orderMngAction({ type: "remove", orderLineId });
  }
  if (order.orderLines.length === 0) return null;
  return (
    <div className="fixed bottom-0 bg-card left-0 right-0 p-1">
      {displayItems && (
        <div className="px-3 pb-2">
          <Button variant="outline" size="sm" className="my-1" onClick={onClear}>
            <MdClear className="mr-2" />
            Vider le panier
          </Button>
          <div>
            {order.orderLines.map((ol) => (
              <div className="p-1 m-2 flex justify-between border-2 border-zinc-200" key={ol.id}>
                <div className="flex-grow">{ol.label}</div>
                <div className="px-5">{ol.amount}</div>
                <div className="px-2">{formatPrice(ol.price)}</div>
                <div className="px-2">
                  <Button size="sm" variant="destructive" onClick={() => onRemove(ol.id)}>
                    <MdClear className="" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="flex justify-between align-middle">
        {displayItems ? (
          <Button variant="outline" onClick={() => setDisplayItems(false)}>
            Masquer
          </Button>
        ) : (
          <Button variant="outline" onClick={() => setDisplayItems(true)}>
            {order.orderLines.length} élément
            {order.orderLines.length > 1 ? "s" : ""}
          </Button>
        )}
        <div className="px-5 pt-2">{formatPrice(order.price)}</div>
        {displayItems ? (
          <Button variant="default" className="bg-green-700" onClick={() => setDisplayItems(true)}>
            Confirmer
          </Button>
        ) : (
          <Button variant="outline" className="" onClick={() => setDisplayItems(true)}>
            <IoCartOutline className="w-7 h-7 mr-2" /> Panier
          </Button>
        )}
      </div>
    </div>
  );
}
