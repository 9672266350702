"use client";

import { getIsTouchDevice } from "@/functions/device";
import { useEditUiContext } from "@/providers/edit/edit-ui-provider";
import { useWebDataContext } from "@/providers/web-data/web-data-provider";
import { useWebUiDispatch } from "@/providers/webui/web-ui-provider";
import { getNewItem, setItemAction } from "@/server/actions/action-menu";
import { CategoryWithItemIdsType } from "@lifizy/domain-next";
import { Button } from "@lifizy/ui";
import { startTransition, useEffect, useState } from "react";
import DraggableSlider from "./draggable-slider";
import SimpleSlider from "./simple-slider";
import SliderItems from "./slider-items";
export default function SliderCategory({
  category,
  hasDensity = false,
  filters,
  search,
}: {
  category: CategoryWithItemIdsType;
  hasDensity?: boolean;
  filters: string[];
  search: string;
}) {
  const { getCategoryItems } = useWebDataContext();
  const { webAdminForm } = useEditUiContext();
  const { updateState } = useWebDataContext();
  const [onSlide, setOnSlide] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(true);
  const dispatchWebUi = useWebUiDispatch();
  function onStartDrag() {
    setOnSlide(true);
  }
  function onEndDrag() {
    setOnSlide(false);
  }
  function onLocalClick(itemId: string) {
    if (!onSlide) dispatchWebUi({ type: "selectItem", selectedItemId: itemId });
  }
  useEffect(() => {
    //detect touch device on client side only by using useEffect
    setIsTouchDevice(getIsTouchDevice());
  }, []);
  return (
    <div className="relative pb-8 pt-8">
      <div className={`flex`}>
        <div className={`ml-6 animate-label ${hasDensity ? "text-4xl" : "text-5xl"}`}>
          {category.title}
        </div>
        {webAdminForm === "menu" && (
          <div className="">
            <Button
              variant="app"
              className="ml-4 mt-5"
              onClick={async () => {
                const newItem = await getNewItem();
                startTransition(() => {
                  updateState({ type: "addItem", category: category, item: newItem });
                });
                onLocalClick(newItem.id!);
                await setItemAction(newItem, category.id!, true);
              }}>
              +
            </Button>
          </div>
        )}
      </div>
      {isTouchDevice ? (
        <SimpleSlider hasDensity={hasDensity}>
          <SliderItems
            items={getCategoryItems(category.id)}
            onClick={onLocalClick}
            hasDensity={hasDensity}
            filters={filters}
            search={search}
          />
        </SimpleSlider>
      ) : (
        <DraggableSlider onStartDrag={onStartDrag} onEndDrag={onEndDrag}>
          <SliderItems
            items={getCategoryItems(category.id)}
            onClick={onLocalClick}
            hasDensity={hasDensity}
            filters={filters}
            search={search}
          />
        </DraggableSlider>
      )}
    </div>
  );
}
