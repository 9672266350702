import { useIsNextDisabled } from "@/providers/order/order-manager-provider";
import { NavigationButton } from "./navigation-button";

export const NextButton = ({
  currentChoiceId,
  onClick,
}: {
  currentChoiceId: string;
  onClick: () => void;
}) => {
  const disableNext = useIsNextDisabled(currentChoiceId);
  if (currentChoiceId === "summary") return <NavigationButton type="addToCart" onClick={onClick} />;
  return <NavigationButton disabled={disableNext} type="next" onClick={onClick} />;
};
