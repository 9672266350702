import { ItemChoiceGroupTreeType, ItemChoiceTreeType, ItemType } from "@lifizy/domain-next";
import { useAnimate } from "framer-motion";
import { useEffect, useState } from "react";
import { ItemFormSummary } from "../item-form-summary";
import ChoiceForm from "./choice-form";
interface ChoiceViewProps {
  item: ItemType;
  choice: ItemChoiceTreeType;
  itemChoiceGroup: ItemChoiceGroupTreeType;
  onNext: () => void;
}
export default function ChoiceAnimator({ item, choice, itemChoiceGroup }: ChoiceViewProps) {
  const [scope, animate] = useAnimate();
  const [activeChoiceId, setActiveChoiceId] = useState<string | undefined>(choice.id);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const animation = async (isForward: boolean) => {
      if (isForward) {
        await animate(scope.current, { x: "-100%" }, { duration: 0.2, ease: "linear" });
        setActiveChoiceId(choice.id);
        await animate(scope.current, { x: "100%" }, { duration: 0.001 });
        animate(scope.current, { x: "0" }, { duration: 0.2, ease: "linear" });
      } else {
        await animate(scope.current, { x: "100%" }, { duration: 0.2, ease: "linear" });
        setActiveChoiceId(choice.id);
        await animate(scope.current, { x: "-100%" }, { duration: 0.001 });
        animate(scope.current, { x: "0" }, { duration: 0.2, ease: "linear" });
      }
    };
    if (isLoaded) {
      if (choice === undefined) {
        animation(true);
        return;
      }
      if (activeChoiceId === undefined) {
        animation(false);
        return;
      }
      const activeChoice = itemChoiceGroup.choices.find((ch) => ch.id === activeChoiceId);
      if (activeChoice)
        animation(
          itemChoiceGroup.choices.indexOf(activeChoice) < itemChoiceGroup.choices.indexOf(choice)
        );
    } else setIsLoaded(true);
  }, [choice.id]);
  const choiceForm = () => {
    const choice = itemChoiceGroup.choices.find((ch) => ch.id === activeChoiceId);
    if (!choice) return;
    return <ChoiceForm item={item} choice={choice} choiceGroupId={itemChoiceGroup.id} />;
  };
  return (
    <div ref={scope}>
      {activeChoiceId ? (
        <div id={`choice-${activeChoiceId}`}>
          <div className="text-xl">{item.title}</div>
          {choiceForm()}
        </div>
      ) : (
        <ItemFormSummary item={item} />
      )}
    </div>
  );
}
