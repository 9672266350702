/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import type { ReactElement } from "react";
import { useRef, useState } from "react";

function Draggable({ children }: { children: ReactElement }) {
  // * When a mouse is used (not on mobile)  -> simuate a draggable
  const ourRef = useRef<HTMLDivElement>(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    scrollLeft: 0,
  });

  const handleDragStart = (e: React.MouseEvent) => {
    if (!ourRef.current) return;
    const slider = ourRef.current.children[0] as HTMLElement;
    const startX = e.pageX - slider.offsetLeft;
    const scrollLeft = slider.scrollLeft;
    mouseCoords.current = { startX, scrollLeft };
    setIsMouseDown(true);
  };
  const handleDragEnd = () => {
    setIsMouseDown(false);
  };
  const handleDrag = (e: React.MouseEvent) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();

    const slider = ourRef.current.children[0] as HTMLElement;
    const x = e.pageX - slider.offsetLeft;
    const walkX = (x - mouseCoords.current.startX) * 1.75;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions -- too restrictive here
    <div
      onMouseDown={handleDragStart}
      onMouseLeave={handleDragEnd}
      onMouseMove={handleDrag}
      onMouseUp={handleDragEnd}
      ref={ourRef}>
      {children}
    </div>
  );
}

export default Draggable;
