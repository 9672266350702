import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType, { TargetElement } from "split-type";

const createAnimation = () => {
  gsap.registerPlugin(ScrollTrigger);
  const splitTypes = document.querySelectorAll(".animate-label");
  splitTypes.forEach((char) => {
    const text = new SplitType(char as TargetElement, { types: "chars" });
    gsap.from(text.chars, {
      // color: fg,
      duration: 1,
      stagger: 0.08,
      opacity: 0.5,
      y: -10,
      scrollTrigger: {
        scroller: "#menu-animation-scroll-area",
        trigger: char,
        start: "top 80%",
        end: "top 20%",
        scrub: false,
        markers: false,
      },
    });
  });
};

export default createAnimation;
