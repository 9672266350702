"use client";
import FilterGroupType from "@/model/filter-group";
import { Accordion, Button, Input } from "@lifizy/ui";
import { MdClear } from "react-icons/md";
import FilterGroup from "./filter-group";

interface FilterProps {
  onFilter: (id: string | null, element: string | null) => void;
  filterGroups: FilterGroupType[];
  search: string;
  setSearch: (search: string) => void;
}
export default function FilterForm({
  onFilter,
  search,
  setSearch,
  filterGroups,
}: FilterProps): JSX.Element {
  return (
    <div className="flex-none w-full pb-3">
      <div className="pb-3 mx-2 border-b flex w-full max-w-sm items-center space-x-2">
        <Input
          className="focus-visible:ring-1 focus-visible:ring-offset-1"
          autoFocus
          placeholder="🔍 Plat ou ingrédient..."
          value={search}
          onChange={(e) => {
            setSearch(e.currentTarget.value);
          }}
        />
        <Button variant="outline" onClick={() => setSearch("")}>
          <MdClear />
        </Button>
      </div>
      <Accordion type="multiple" className="px-2 pt-0 text-left" defaultValue={["1"]}>
        {filterGroups.map((filterGroup) => (
          <FilterGroup key={filterGroup.id} filterGroup={filterGroup} onFilter={onFilter} />
        ))}
      </Accordion>
    </div>
  );
}
