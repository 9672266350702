import { ItemType } from "@lifizy/domain-next";

function filterOnIngredients(item: ItemType, filters: string[]): boolean {
  if (filters.length === 0) return true;
  if (!item.ingredients) return true;
  return item.ingredients.includes(filters[0]);
}
export function filterItems(item: ItemType, search: string, filters: string[]) {
  if (search !== "") {
    if (item.title && item.title.toLowerCase().includes(search.toLowerCase()))
      return filterOnIngredients(item, filters);
    if (item.ingredients)
      return (
        item.ingredients.some((ingredient) =>
          ingredient.toLowerCase().includes(search.toLowerCase())
        ) && filterOnIngredients(item, filters)
      );
  }
  return filterOnIngredients(item, filters);
}
