"use client";

import BasicContentEditable from "@/components/edit/basic-content-editable";
import OptionView from "@/components/menu/item/choice/option-view";
import { useEditUiContext } from "@/providers/edit/edit-ui-provider";
import { useWebDataContext } from "@/providers/web-data/web-data-provider";
import { deletedItemOptionAction, setItemChoiceAction } from "@/server/actions/action-menu";
import {
  getNewItemOption,
  ItemChoiceTreeType,
  ItemOptionType,
  ItemType,
} from "@lifizy/domain-next";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@lifizy/ui";
import { startTransition, useEffect, useState, type ReactNode } from "react";
import { FcDataConfiguration } from "react-icons/fc";
import { HiOutlineTrash } from "react-icons/hi";

export default function ChoiceForm({
  item,
  choice,
  choiceGroupId,
}: {
  item: ItemType;
  choice: ItemChoiceTreeType;
  choiceGroupId: string;
}): ReactNode {
  const [display, setDisplay] = useState(
    choice.min === 1 && choice.max === 1 ? "unique" : "multiple"
  );
  const [cols, setCols] = useState(3);
  const { updateState, getGroupId } = useWebDataContext();
  const { webAdminForm, active } = useEditUiContext();
  useEffect(() => {
    if (!choice) return;
    if (display === "multiple") {
      setCols(1);
      return;
    }
    if (choice.options.length === 2 || choice.options.length === 4) {
      setCols(2);
      return;
    }
    setCols(3);
  }, [display, choice]);

  useEffect(() => {
    setDisplay(choice.min === 1 && choice.max === 1 ? "unique" : "multiple");
  }, [choice.min, choice.max]);
  const updateChoice = async (choice: ItemChoiceTreeType) => {
    startTransition(() => {
      updateState({ type: "updateItemChoice", choiceGroupId, choice });
    });
    await setItemChoiceAction(choice, choiceGroupId, getGroupId());
  };
  const changeDisplay = async (display: "unique" | "multiple") => {
    updateChoice({
      ...choice,
      min: display === "unique" ? 1 : 0,
      max: display === "unique" ? 1 : 10,
    });
    setDisplay(display);
  };
  const addOption = async () => {
    const newChoice = {
      ...choice,
      options: [
        ...choice.options,
        await getNewItemOption(`Option ${choice.options.length + 1}`, choice.options.length),
      ],
    };
    startTransition(() => {
      updateState({
        type: "updateItemChoice",
        choiceGroupId,
        choice: newChoice,
      });
    });
    await setItemChoiceAction(newChoice, choiceGroupId, getGroupId());
  };
  const onChangeOption = async (option: ItemOptionType) => {
    const stateOption = choice.options.find((o) => o.id === option.id);
    if (!stateOption) return;
    if (stateOption.order === undefined || option.order === undefined) return;
    let newOptions = choice.options.map((o) => (o.id === option.id ? option : o));
    if (stateOption.order !== option.order) {
      // Must find the option to switch order with
      const switchOption = choice.options.find((o) => o.order === option.order);
      if (switchOption && switchOption.order !== undefined) {
        newOptions = newOptions.map((o) =>
          o.id === switchOption.id ? { ...switchOption, order: stateOption.order } : o
        );
      }
    }
    updateChoice({ ...choice, options: newOptions });
  };
  const onRemoveOption = async (optionId: string) => {
    const option = choice.options.find((o) => o.id === optionId);
    if (!option) return;
    const newChoice = {
      ...choice,
      options: choice.options.filter((o) => o.id !== optionId),
    };
    startTransition(() => {
      updateState({
        type: "updateItemChoice",
        choiceGroupId,
        choice: newChoice,
      });
    });
    await deletedItemOptionAction(optionId, option.itemId!, getGroupId());
  };
  return (
    <div className="">
      {/* <div className="text-xl">{item.title}</div> */}
      <div className="text pb-1 pt-3 relative">
        <BasicContentEditable
          html={choice.title!}
          onChange={(value) => {
            updateChoice({ ...choice, title: value });
          }}
          editOn={active && webAdminForm === "menu"}
        />
        {active && webAdminForm === "menu" && (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button variant="app" size="sm" className="absolute top-1 right-0 p-2">
                <FcDataConfiguration className="w-5 h-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-editor-background text-editor-text font-sans absolute bottom-0 ">
              <DropdownMenuLabel>Configuration de l&apos;étape</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuRadioGroup value={display} onValueChange={changeDisplay}>
                <DropdownMenuRadioItem value="unique">
                  Un choix parmi plusieurs
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="multiple">Des choix multiples</DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>

              <DropdownMenuSeparator />
              <DropdownMenuItem>
                <span className="mr-2">Position</span>
                <Button
                  size="xs"
                  variant="outline"
                  className="mr-2 px-2 rounded-[2px]"
                  disabled={choice.order === 0}
                  onClick={() => {
                    updateChoice({ ...choice, order: choice.order! - 1 });
                  }}>
                  -
                </Button>
                {choice.order! + 1}
                <Button
                  size="xs"
                  variant="outline"
                  className="ml-2 px-2 rounded-[2px]"
                  // disabled={choice.order === choice.options.length - 1}
                  onClick={() => {
                    updateChoice({ ...choice, order: choice.order! + 1 });
                  }}>
                  +
                </Button>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={() => {}}>
                <HiOutlineTrash className="mr-2" /> Supprimer
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
        {/* <ContentEditable
              className={`${webAdminForm === "menu" ? "outline" : ""}  outline-1 outline-dashed text-balance w-fit m-auto px-1`}
              disabled={webAdminForm !== "menu"}
              html={step.question}
              onChange={() => {}}
            /> */}
      </div>
      <div
        className={`cursor-pointer grid ${cols === 1 ? "grid-cols-1" : cols === 2 ? "grid-cols-2" : "grid-cols-3"} gap-1`}>
        {choice.options
          .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
          .map((option) => (
            <OptionView
              key={option.id}
              choice={choice}
              img={item.imageUrl!}
              option={option}
              onNext={() => {}}
              multi={display === "multiple"}
              onRemove={onRemoveOption}
              onChange={onChangeOption}
            />
          ))}
      </div>
      {active && webAdminForm === "menu" && (
        <Button
          variant="app"
          size="xs"
          className="mt-2 px-3 text-xs rounded-sm"
          onClick={addOption}>
          Ajouter une option
        </Button>
      )}
    </div>
  );
}
