/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import type { ReactElement } from "react";
import { useEffect, useRef, useState } from "react";
import SimpleSlider from "./simple-slider";

// * When a mouse is used (not on mobile)  -> simuate a draggable slider with grab cursor
function DraggableSlider({
  children,
  onStartDrag,
  onEndDrag,
}: {
  children: ReactElement;
  onStartDrag: () => void;
  onEndDrag: () => void;
}) {
  const dragDivRef = useRef<HTMLDivElement>(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    scrollLeft: 0,
  });
  useEffect(() => {
    if (!dragDivRef.current) return;
    const slider = dragDivRef.current.children[0] as HTMLElement;
    slider.addEventListener("scroll", (e) => {
      console.log("scrolling");
      const target = e.target as HTMLTextAreaElement;
      const images = slider.getElementsByClassName("image-to-rotate");
      if (images && images.length > 0)
        for (let i = 0; i < images.length; i++) {
          const image = images[i] as HTMLImageElement;
          image.style.transform = `rotate(${target.scrollLeft / 10}deg)`;
        }
      // slider.scrollLeft = target.scrollTop / 10;
      // if (startTop === -1) {
      //   startTop = target.scrollTop;
      //   startLeft = slider.scrollLeft;
      // }
      // slider.scrollLeft = startLeft + (target.scrollTop - startTop) / 10;
    });
  }, []);

  let velX = 0;
  let momentumID;
  function beginMomentumTracking() {
    cancelMomentumTracking();
    momentumID = requestAnimationFrame(momentumLoop);
  }
  function cancelMomentumTracking() {
    cancelAnimationFrame(momentumID);
  }
  function momentumLoop() {
    if (!dragDivRef.current) return;
    const slider = dragDivRef.current.children[0] as HTMLElement;
    slider.scrollLeft += velX;
    velX *= 0.95;
    if (Math.abs(velX) > 0.5) {
      momentumID = requestAnimationFrame(momentumLoop);
    }
  }

  const handleDragStart = (e: React.MouseEvent) => {
    if (!dragDivRef.current) return;
    const slider = dragDivRef.current.children[0] as HTMLElement;
    const startX = e.pageX - slider.offsetLeft;
    const scrollLeft = slider.scrollLeft;
    mouseCoords.current = { startX, scrollLeft };
    setIsMouseDown(true);
    cancelMomentumTracking();
  };
  const handleDragEnd = () => {
    setIsMouseDown(false);
    setTimeout(() => onEndDrag(), 200);
    beginMomentumTracking();
  };
  const handleDrag = (e: React.MouseEvent) => {
    if (!isMouseDown || !dragDivRef.current) return;
    e.preventDefault();

    const slider = dragDivRef.current.children[0] as HTMLElement;
    const x = e.pageX - slider.offsetLeft;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    const prevScrollLeft = slider.scrollLeft;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    velX = slider.scrollLeft - prevScrollLeft;
    if (Math.abs(walkX) > 15)
      //Fire real start drag to avoid click during drag
      onStartDrag();
  };

  return (
    <div
      onMouseDown={handleDragStart}
      onMouseLeave={handleDragEnd}
      onMouseMove={handleDrag}
      onMouseUp={handleDragEnd}
      ref={dragDivRef}>
      <SimpleSlider>{children}</SimpleSlider>
    </div>
  );
}

export default DraggableSlider;
