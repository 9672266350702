import { formatPrice } from "@/functions/dataFormat";
import { useWebDataContext } from "@/providers/web-data/web-data-provider";
import { useWebUiDispatch } from "@/providers/webui/web-ui-provider";
import type { CategoryWithItemIdsType } from "@lifizy/domain-next";
import { Button } from "@lifizy/ui";
import { FC } from "react";
import { IoAddOutline } from "react-icons/io5";
import { filterItems } from "../../search/filter/filter";
const display = [
  {
    hasDensity: false,
    hasFull: true,
    container: "grid-cols-1",
    item: "",
    footer: "p-4 pb-10",
  },
  {
    hasDensity: false,
    hasFull: false,
    container: "grid-cols-1",
    item: "px-8 py-4",
    footer: "py-3",
  },
  {
    hasDensity: true,
    hasFull: true,
    container: "grid-cols-2",
    item: "",
    footer: "p-3",
  },
  {
    hasDensity: true,
    hasFull: false,
    container: "grid-cols-2 gap-4 px-4",
    item: "",
    footer: "py-3",
  },
];
interface FlatCategoryProps {
  category: CategoryWithItemIdsType;
  hasMulticolor: boolean;
  hasDensity: boolean;
  colors: string[];
  textColor: string;
  search: string;
  filters: string[];
  hasFull: boolean;
}

export const FlatCategory: FC<FlatCategoryProps> = ({
  category,
  hasMulticolor,
  hasDensity,
  search,
  filters,
  colors,
  hasFull,
}) => {
  const { getCategoryItems } = useWebDataContext();
  const dispatchWebUi = useWebUiDispatch();
  function getTailwindClass(elementKey: string) {
    const displayCase = display.find(
      (element) => element.hasFull === hasFull && element.hasDensity === hasDensity
    );
    if (!displayCase) return "";
    return displayCase[elementKey];
  }
  function onClick(itemId: string) {
    dispatchWebUi({ type: "selectItem", selectedItemId: itemId });
  }
  const items = getCategoryItems(category.id);
  return (
    <div
      id={category.id}
      style={{ scrollMarginTop: 120 }}
      className={`pb-10  ${hasMulticolor ? "bg-background" : "bg-gradient-to-br from-gradient-from to-gradient-to  text-gradient-text"}`}>
      <div
        className={`@lg:text-[70px]  text-center ${hasDensity ? "pt-8 pb-4 text-[30px] " : "pt-10 pb-6 text-[35px] "}`}>
        {category.title}
      </div>
      <div className={`grid ${getTailwindClass("container")}`}>
        {items
          .filter((item) => filterItems(item, search, filters))
          .map((i, idx) => (
            <div
              key={idx}
              className={`${getTailwindClass("item")}}
            ${hasMulticolor ? (colors.length > idx ? colors[idx] : colors[0]) : "border-t border-gradient-from"} }`}>
              {!hasDensity && !hasFull && (
                <div
                  className={`py-10 ${hasDensity ? "text-2xl" : "text-4xl"} @lg:text-[60px] text-center @lg:h-[90px] animate-label`}>
                  {i.title}
                </div>
              )}
              <div>
                {i.imageUrl && (
                  <img
                    src={i.imageUrl}
                    alt={i.title ? i.title : "image"}
                    className="mx-auto  cursor-pointer w-full"
                    onClick={() => onClick(i.id!)}
                  />
                )}
              </div>
              {hasDensity || hasFull ? (
                <div className={getTailwindClass("footer")}>
                  <div className="flex justify-between w-full">
                    <div className={`${hasDensity ? "text-lg" : "text-2xl"} animate-label`}>
                      {i.title}
                    </div>
                    <div>
                      <Button variant="default" size="sm" onClick={() => onClick(i.id!)}>
                        <IoAddOutline className={hasDensity ? "" : "mr-1"} />
                        {hasDensity ? "" : "Commander"}
                      </Button>
                    </div>
                  </div>
                  <div className={`${hasDensity ? "text-lg" : "text-xl"}`}>
                    {formatPrice(i.price)}
                  </div>
                  <div className={`text-balance ${hasDensity ? "text-xs" : "text-sm"}`}>
                    {i.ingredients.join(", ")}
                  </div>
                </div>
              ) : (
                <div className="pt-5">
                  <div className={`px-4 text-center text-xl pb-8 text-balance `}>
                    {i.ingredients.join(", ")}
                  </div>
                  <div className={`px-6 text-center text-2xl pb-8`}>{formatPrice(i.price)}</div>
                  <div className="text-center pb-12">
                    <Button onClick={() => onClick(i.id!)}>
                      <IoAddOutline className="mr-1" />
                      Commander
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
