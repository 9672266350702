import { useEffect, useRef, type ReactElement } from "react";

function SimpleSlider({ children, hasDensity }: { children: ReactElement; hasDensity?: boolean }) {
  const dragDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!dragDivRef.current) return;
    const slider = dragDivRef.current as HTMLElement;
    slider.addEventListener("scroll", () => {
      const images = slider.getElementsByClassName("image-to-rotate");
      if (images && images.length > 0)
        for (let i = 0; i < images.length; i++) {
          const image = images[i] as HTMLImageElement;
          image.style.transform = `rotate(${slider.scrollLeft / 10}deg)`;
        }
    });
  }, []);
  return (
    <div
      ref={dragDivRef}
      id="draggable"
      className="w-screen overflow-x-scroll overscroll-x-none cursor-grab"
      style={{
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='2000' height='100' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1043%26quot%3b)' fill='none'%3e%3cpath d='M -2392.6570121739087%2c214 C -2104.66%2c239 -1528.66%2c343.6 -952.6570121739088%2c339 C -376.66%2c334.4 8.81%2c189.2 487.3429878260912%2c191 C 965.87%2c192.8 1249.47%2c316.6 1440%2c348' stroke='rgba(255%2c 255%2c 255%2c 0.58)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1043'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e")`,
        backgroundPosition: hasDensity ? "70% 38%" : "70% 44%",
        backgroundRepeat: "repeat-x",
        backgroundSize: "auto",
      }}>
      {children}
    </div>
  );
}

export default SimpleSlider;
