import FilterGroupType from "@/model/filter-group";
import { useWebUiContext, useWebUiDispatch } from "@/providers/webui/web-ui-provider";
import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from "@lifizy/ui";
import FilterForm from "./filter/filter-form";
export default function FilterDialog({
  onFilter,
  filterGroups,
  search,
  setSearch,
  clear,
}: {
  onFilter: (id: string, element: string | null) => void;
  filterGroups: FilterGroupType[];
  search: string;
  setSearch: (search: string) => void;
  clear: () => void;
}): JSX.Element {
  const { displaySearch } = useWebUiContext();
  const dispatch = useWebUiDispatch();
  if (!dispatch) return <>Error</>;
  const handleAction = (state: boolean) => {
    if (state) {
      dispatch({ type: "displaySearchOn" });
    } else {
      dispatch({ type: "displaySearchOff" });
    }
  };
  return (
    <Drawer open={displaySearch} onOpenChange={handleAction}>
      <DrawerContent className="">
        <DrawerHeader>
          <FilterForm
            onFilter={onFilter}
            filterGroups={filterGroups}
            search={search}
            setSearch={setSearch}
          />
        </DrawerHeader>
        <DrawerFooter>
          <div className="flex justify-between">
            <Button
              variant="outline"
              onClick={() => {
                clear();
                handleAction(false);
              }}>
              Effacer tout
            </Button>
            <Button variant="outline" onClick={() => handleAction(false)}>
              Valider
            </Button>
          </div>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
