"use client";
import { useEditUiContext } from "@/providers/edit/edit-ui-provider";
import { useOrderManagerDispatch } from "@/providers/order/order-manager-provider";
import { useWebUiDispatch } from "@/providers/webui/web-ui-provider";
import { ItemChoiceGroupTreeType, ItemType } from "@lifizy/domain-next";
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from "@lifizy/ui";
import { useEffect, useState } from "react";
import ChoiceAnimator from "./choice/choice-animator";
import { ItemFormFooter } from "./footer/item-form-footer";

export default function ItemDialog({
  item,
  itemChoiceGroup,
}: {
  item: ItemType;
  itemChoiceGroup: ItemChoiceGroupTreeType;
}): JSX.Element {
  const { active, webAdminForm } = useEditUiContext();
  const dispatchWebUi = useWebUiDispatch();
  const dispatchOrderMng = useOrderManagerDispatch();
  const [currentChoiceId, setCurrentChoiceId] = useState<string>(
    itemChoiceGroup.choices.length > 0 ? itemChoiceGroup.choices[0].id : "summary"
  );

  useEffect(() => {
    dispatchOrderMng({ type: "initCurrent", item: item, settings: [] });
  }, [item, dispatchOrderMng]);

  function onClose() {
    dispatchWebUi({ type: "selectItem", selectedItemId: null });
  }
  function onAddToCart() {
    dispatchOrderMng({ type: "add" });
    dispatchWebUi({ type: "selectItem", selectedItemId: null });
  }
  useEffect(() => {
    console.log("itemChoiceGroup", itemChoiceGroup);
  }, [itemChoiceGroup]);

  function onPrevious() {
    if (itemChoiceGroup.choices.length === 0 || itemChoiceGroup.choices[0].id === currentChoiceId) {
      onClose();
      return;
    }
    let futureChoiceId: string | null = null;
    if (currentChoiceId === "summary")
      futureChoiceId = itemChoiceGroup.choices[itemChoiceGroup.choices.length - 1].id;
    else {
      const index = itemChoiceGroup.choices.findIndex((s) => s.id === currentChoiceId);
      if (index === -1) return;
      futureChoiceId = itemChoiceGroup.choices[index - 1].id;
    }
    if (futureChoiceId) {
      setCurrentChoiceId(futureChoiceId);
    }
  }
  function onNext() {
    if (currentChoiceId === "summary") {
      onAddToCart();
      return;
    }
    console.log("next", currentChoiceId, itemChoiceGroup);
    const index = itemChoiceGroup.choices.findIndex((s) => s.id === currentChoiceId);
    if (index === -1) return;
    const futureChoiceId =
      itemChoiceGroup.choices.length - 1 === index
        ? "summary"
        : itemChoiceGroup.choices[index + 1].id;
    setCurrentChoiceId(futureChoiceId);
  }

  function getChoiceView() {
    const choice = itemChoiceGroup.choices.find((s) => s.id === currentChoiceId);
    if (!choice) return;
    return (
      <ChoiceAnimator
        item={item}
        choice={choice}
        onNext={onNext}
        itemChoiceGroup={itemChoiceGroup}
      />
    );
  }

  return (
    <Drawer
      open={true}
      modal={webAdminForm === ""}
      onOpenChange={(state: boolean) => {
        if (!state) onClose();
      }}>
      <DrawerContent className={active ? "mb-[60px]" : ""}>
        <DrawerHeader>{getChoiceView()}</DrawerHeader>
        <DrawerFooter className="pt-1">
          <ItemFormFooter
            onPrevious={onPrevious}
            onNext={onNext}
            currentChoiceId={currentChoiceId}
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
