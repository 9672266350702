import { formatPrice } from "@/functions/dataFormat";
import { useOrderManagerContext } from "@/providers/order/order-manager-provider";
import { NavigationButton } from "./navigation-button";
import { NextButton } from "./next-button";

// Création de la nouvelle composante
export const ItemFormFooter = ({
  onPrevious,
  onNext,
  currentChoiceId,
}: {
  currentChoiceId: string;
  onPrevious: () => void;
  onNext: () => void;
}) => {
  const { currentOrderLine } = useOrderManagerContext();
  return (
    <div className="flex justify-between">
      <NavigationButton type="previous" onClick={onPrevious} />
      <div className="pt-2">{currentOrderLine && formatPrice(currentOrderLine.price)}</div>
      <NextButton currentChoiceId={currentChoiceId} onClick={onNext} />
    </div>
  );
};
