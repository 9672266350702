import { useEditUiContext } from "@/providers/edit/edit-ui-provider";

import { ItemType } from "@lifizy/domain-next";
import { filterItems } from "../../search/filter/filter";
import SliderItem from "./slider-item";
export default function SliderItems({
  items,
  onClick,
  hasDensity = false,
  filters,
  search,
}: {
  items: ItemType[];
  hasDensity?: boolean;
  onClick: (itemId: string) => void;
  filters: string[];
  search: string;
}) {
  const { webAdminForm, active } = useEditUiContext();
  return (
    <div>
      <div className={`flex flex-row ${hasDensity ? "pt-2" : "pt-6"}`}>
        {items
          .filter((item) => filterItems(item, search, filters))
          .map((item, idx) => (
            <div
              key={idx}
              className={`${hasDensity ? "w-[45%]" : "w-[70%]"} @md:w-[60%] @lg:w-[40%] flex-shrink-0`}>
              <SliderItem
                item={item}
                editOn={active && webAdminForm === "menu"}
                onClick={onClick}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
