import { Button } from "@lifizy/ui";
import { BsFillBagPlusFill } from "react-icons/bs";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

export const NavigationButton = ({
  type,
  onClick,
  disabled,
}: {
  type: "previous" | "next" | "addToCart";
  onClick: () => void;
  disabled?: boolean;
}) => (
  <Button
    variant={type === "addToCart" ? "default" : "outline"}
    onClick={onClick}
    disabled={disabled}>
    {type === "previous" ? <GrFormPrevious className="pr-1 w-6 h-6" /> : null}
    {type === "addToCart" ? <BsFillBagPlusFill className="pr-1 w-6 h-6" /> : null}
    {type === "next" ? "Suivant" : type === "previous" ? "Précédent" : "Ajouter"}
    {type === "next" ? <GrFormNext className="pl-1 w-6 h-6" /> : null}
  </Button>
);
