"use client";

export function getIsTouchDevice(): boolean {
  interface NavigatorMs extends Navigator {
    msMaxTouchPoints: number;
  }
  const navigatorMs = navigator as NavigatorMs;
  return (
    "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigatorMs.msMaxTouchPoints > 0
  );
}

export function desactivateBorderTouch() {
  //desactivate swipe on navigate gesture on mobile
  window.addEventListener(
    "touchstart",
    (e) => {
      const touch = e.touches[0] || e.changedTouches[0];
      // is not near edge of view, exit
      if (touch.pageX > 15 && touch.pageX < window.innerWidth - 15) return;
      e.preventDefault();
    },
    { passive: false }
  );
  window.addEventListener(
    "touchforcechange",
    (e) => {
      e.preventDefault();
    },
    { passive: false }
  );
}
