"use client";
import { formatPrice } from "@/functions/dataFormat";
import { useOrderManagerContext } from "@/providers/order/order-manager-provider";
import { ItemType } from "@lifizy/domain-next";

export const ItemFormSummary = ({ item }: { item: ItemType }) => {
  const { currentOrderLine } = useOrderManagerContext();
  return (
    <div>
      <div id="step-summary pb-3 ">
        <div className="text-lg">
          Voulez-vous ajouter {item.title}
          <br /> à la commande ?
        </div>
        <table className="w-full m-3 text-base">
          <tbody>
            {currentOrderLine &&
              currentOrderLine.settings.map((s) => (
                <tr className="" key={s.choiceId}>
                  <td className="text-left">{s.label}</td>
                  <td className="text-left">
                    {s.options.map((c) => (
                      <div key={c.optionId}>
                        {c.label} {c.amount > 1 && "x" + c.amount}
                      </div>
                    ))}
                  </td>
                  <td className="text-right pr-3">+{formatPrice(s.price)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
