import BasicContentEditable from "@/components/edit/basic-content-editable";
import { formatPrice, getPriceAsNumber } from "@/functions/dataFormat";
import { useWebDataContext } from "@/providers/web-data/web-data-provider";
import { useWebUiContext } from "@/providers/webui/web-ui-provider";
import { setItemAction, setItemImageAction } from "@/server/actions/action-menu";
import { ItemType } from "@lifizy/domain-next";
import { Button } from "@lifizy/ui";
import { useLongPress } from "@uidotdev/usehooks";
import Image from "next/image";
import { startTransition, useState } from "react";
import { ContentEditableEvent } from "react-contenteditable";
import { BsFillBagPlusFill } from "react-icons/bs";
import { RiImageEditFill } from "react-icons/ri";
import { toast } from "sonner";
let timer: NodeJS.Timeout | null = null;
export default function SliderItem({
  item,
  onClick,
  editOn,
}: {
  item: ItemType;
  editOn: boolean;
  onClick: (itemId: string | null) => void;
}) {
  const [uploading, setUploading] = useState(false);
  const { selectedItemId } = useWebUiContext();
  const { updateState } = useWebDataContext();
  const onLongPress = useLongPress((e) => {
    onClick(item.id);
    e.preventDefault();
  });
  const onChangeTitle = (value: string) => {
    updateItem({ ...item, title: value });
  };
  const onChangePrice = async (e: ContentEditableEvent) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      updateItem({ ...item, price: getPriceAsNumber(e.target.value) });
    }, 2000);
  };
  // const onChangePriceImmediate = async (e) => {
  //   if (
  //     e.target.innerText === "" ||
  //     e.target.innerText === null ||
  //     e.target.innerText === undefined
  //   )
  //     return;
  //   if (timer) clearTimeout(timer);
  //   updateItem({ ...item, price: getPriceAsNumber(e.target.innerText) });
  // };

  const updateItem = async (item: ItemType) => {
    startTransition(() => {
      updateState({ type: "updateItem", item: item });
    });
    const response = await setItemAction(item);
    if (response.error)
      toast.error("Echec de la sauvegarde", {
        description: response.error,
      });
  };
  const handleUpload = async (file: File) => {
    if (!file) {
      console.log("Please select a file to upload.");
      return;
    }

    setUploading(true);
    console.log("Uploading file:", file.name, file.type, "/api/upload");
    const response = await fetch("/api/upload", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filename: file.name, contentType: file.type, id: item.id }),
    });

    if (response.ok) {
      const { url, fields } = await response.json();

      const formData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value as string);
      });
      formData.append("file", file);

      const uploadResponse = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (uploadResponse.ok) {
        console.log("Upload successful!", url + fields.key);
        if (item.id) await setItemImageAction(item.id, url + fields.key);
      } else {
        console.error("S3 Upload Error:", uploadResponse);
        console.log("Upload failed.");
      }
    } else {
      console.log("Failed to get pre-signed URL.");
    }

    setUploading(false);
  };
  return (
    <div>
      <div className="relative aspect-square mx-[10%] my-3 rounded">
        {item.imageUrl && (
          <Image
            alt="Card Image"
            className={`object-cover ${false ? "image-to-rotate" : ""}`}
            fill
            src={item.imageUrl}
            priority={true}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />
        )}

        {uploading && (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="text-white">Mise en ligne...</div>
          </div>
        )}
        {editOn && (
          <div>
            <input
              id="file"
              type="file"
              onChange={(e) => {
                const files = e.target.files;
                if (files) {
                  handleUpload(files[0]);
                }
              }}
              hidden
              accept="image/png, image/jpeg"
            />
            <label htmlFor="file">
              <RiImageEditFill />
            </label>
          </div>
        )}
      </div>
      <div>
        <div className="text-base px-2">
          <BasicContentEditable
            html={item.title!}
            editOn={editOn}
            onChange={(value) => {
              onChangeTitle(value);
            }}></BasicContentEditable>
        </div>
        <div className="text-xs pt-1 px-4 md:px-6 line-clamp-2 min-h-[35px]">
          <BasicContentEditable
            html={item.ingredients.join(", ")}
            editOn={editOn}
            onChange={() => {}}></BasicContentEditable>
        </div>
        <div className="text-center pt-1 px-2">
          <BasicContentEditable
            html={formatPrice(item.price)}
            editOn={editOn}
            onChange={(value) => {
              onChangePrice(value);
            }}></BasicContentEditable>
        </div>
        <div className="text-center pt-3">
          <Button
            variant="outline"
            onClick={() => onClick(item.id)}
            {...onLongPress}
            className={selectedItemId === item.id ? "bg-gradient-from" : ""}>
            <BsFillBagPlusFill className="mr-2" />
            Commander
          </Button>
          {/* {editOn ? (
            // <Button variant="app" onClick={() => onClick(item.id)} {...onLongPress}>
            //   <FaEdit className="mr-2" />
            // </Button>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button variant="app">
                  <FaEdit className="mr-2" />
                  Modifier
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="bg-editor-background text-editor-text">
                <DropdownMenuItem onClick={() => onClick(item.id)}>Modifier</DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={async () => {
                    startTransition(() => {
                      updateState({ type: "deleteItem", itemId: item.id! });
                    });
                    const response = await deleteItemAction(item.id!);
                    if (response.error)
                      toast.error("Echec de la suppression", {
                        description: response.error,
                      });
                  }}>
                  Supprimer
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <Button
              variant="outline"
              onClick={() => onClick(item.id)}
              {...onLongPress}
              className={selectedItemId === item.id ? "bg-gradient-from" : ""}>
              <BsFillBagPlusFill className="mr-2" />
              Commander
            </Button>
          )} */}
        </div>
      </div>
    </div>
  );
}
